export const ConstantsDzsvg = {
  THREEJS_LIB_URL: 'https://cdnjs.cloudflare.com/ajax/libs/three.js/r73/three.min.js',
  THREEJS_LIB_ORBIT_URL: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/211120/orbitControls.js',
  YOUTUBE_IFRAME_API: 'https://www.youtube.com/iframe_api',
  VIMEO_IFRAME_API: 'https://player.vimeo.com/api/player.js',
  DEBUG_STYLING: 'background-color: #4422aa;',
  DEBUG_STYLING_2: 'color: #ffdada; background-color: #da3333;',
  ANIMATIONS_DURATION: 300,
  DELAY_MINUSCULE: 3,
}

export const DEFAULT_MENU_ITEM_STRUCTURE = `<div class="layout-builder--structure layout-builder--menu-items--layout-default layout-builder--main-con ">
  <div class="layout-builder--item layout-builder--item--11241412321 layout-builder--item--type-container">
    <div class="layout-builder--item layout-builder--item--2312321 layout-builder--item--type-thumbnail navigation-type-image" style="background-image: url({{layout-builder.replace-thumbnail-url}})"></div>
  </div>
  <div class="layout-builder--item layout-builder--item--11241412321 layout-builder--item--type-container">
    <div class="layout-builder--item layout-builder--item--3321321 layout-builder--item--type-title" style=";">{{layout-builder.replace-title}}</div>
    <div class="layout-builder--item layout-builder--item--21312321 layout-builder--item--type-menu-description" style=";">{{layout-builder.replace-menu-description}}</div>
  </div>
</div>`;
export const PLAYER_DEFAULT_RESPONSIVE_RATIO = 0.5625;